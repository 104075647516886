import React from "react"
import Layout from "../components/layout"
import "react-typist/dist/Typist.css"
import Typist from "react-typist"
import { Link } from "gatsby"
import Footer from "../components/styled/footer"
import Social from "../components/social"
import styled from "styled-components"

const NotFound = () => {
  const Text = styled.span`
    color: white;
  `

  return (
    <Layout>
      <Typist className={`typist404`}>
        <Text>404 Error: Page not found</Text>
        <br/>
        <Link to={`/`}>Home</Link>
      </Typist>
      <Social/>
      <Footer/>
    </Layout>
  )
}

export default NotFound
